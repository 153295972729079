<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">账单</div>
      <div class="nav_left_btn" @click="toHome">
        <img :src="cancel" alt width="15" height="25" />
        <!-- <span class="back">返回</span> -->
      </div>
    </div>
    <div class="content">
      <div class="nav-content">
        <div @click="handleChangeDate">
          <span style="font-size: 24px; font-weight: bold">
            {{ thisMonth }}
          </span>
          <span style="font-size: 12px; position: relative">
            <span>月</span>
            <span class="nav-arrows-l" />
          </span>
        </div>
        <div
          style="width: 80px; padding: 5px 0px; box-sizing: border-box"
          @click="handleChangeStatus"
        >
          <div
            style="
              position: relative;
              border: 1px #eee solid;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              height: 30px;
              line-height: 30px;
              padding: 0 10px;
            "
          >
            <div>{{ thisStatus }}</div>
            <div class="nav-arrows-r" />
          </div>
        </div>
      </div>
      <van-list
        v-if="historyList && historyList.length > 0"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="history-content">
          <ul
            v-if="historyList && historyList.length > 0"
            id="box"
            v-infinite-scroll="load"
            infinite-scroll-disabled="disabled"
            class="infinite-list"
            style="overflow: auto"
          >
            <li
              v-for="(item, index) in historyList"
              id="box-item"
              :key="index"
              class="history-items"
            >
              <div class="time-content">
                <div class="item-time">
                  <span>
                    {{ item.payTime ? toDate(item.payTime) : item.createTime }}
                  </span>
                  <van-icon
                    v-if="
                      item.verifyStatus !== 2 &&
                      item.verifyStatus !== 0 &&
                      item.refundStatus !== 5 &&
                      item.type !== 3 &&
                      item.refundStatus !== 3
                    "
                    name="arrow"
                    @click="toDetail(item)"
                  />
                </div>
              </div>
              <div class="items-content">
                <div v-if="item.type === 1" class="item-content">
                  <div class="order-text">充值金额</div>
                  <div class="order-text money" style="color: #f00">
                    +{{ getMoney(item.amount / 100) }}
                  </div>
                </div>
                <div v-else class="item-content">
                  <div class="order-text">退款金额</div>
                  <div class="order-text money" style="color: #000">
                    {{
                      item.verifyStatus === 0
                        ? getMoney(
                            (item.amountApplication === null
                              ? item.amount
                              : item.amountApplication) / 100
                          )
                        : getMoney(item.amount / 100)
                    }}
                  </div>
                </div>

                <div v-if="item.type === 1" class="item-content">
                  <div class="order-text">充值状态</div>
                  <div class="order-text money">
                    {{ item.status === 1 ? '充值失败' : '充值成功' }}
                  </div>
                </div>

                <div v-if="item.type === 4" class="item-content">
                  <div class="order-text">退款状态</div>
                  <div class="order-text money">
                    {{
                      item.verifyStatus === -1
                        ? item.refundStatus === 3
                          ? '退款中'
                          : item.refundStatus === 4
                          ? '退款成功'
                          : '退款失败'
                        : item.verifyStatus === 0
                        ? '审核中'
                        : item.verifyStatus === 1
                        ? item.refundStatus === 3
                          ? '退款中'
                          : item.refundStatus === 4
                          ? '退款成功'
                          : '退款失败'
                        : '审核拒绝'
                    }}
                  </div>
                </div>

                <div v-if="item.type === 3" class="item-content">
                  <div class="oder-text">退款状态</div>
                  <div class="order-text money">退款成功</div>
                </div>

                <div style="margin-top: 0.4rem" />
              </div>
            </li>
          </ul>
        </div>
      </van-list>
      <van-empty v-else description="暂无数据" style="padding-top: 40%" />

      <van-popup v-model="show" position="bottom" :style="{ height: '70%' }">
        <van-datetime-picker
          v-model="currentDate"
          type="year-month"
          title="选择年月"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="handleSelectDate"
          @cancel="handleCancelDate"
        />
      </van-popup>

      <van-popup
        v-model="showStatus"
        position="bottom"
        :style="{ height: '70%' }"
      >
        <van-picker
          title
          show-toolbar
          :columns="columns"
          :default-index="0"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import cancel from '@/assets/cancel.png';
import { getHistory } from '@/api/api';
import { toDate, getMoney } from '@/utils/util';
export default {
  name: 'History',
  data() {
    return {
      cancel,
      num: '',
      historyList: [],
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2099, 10, 1),
      currentDate: new Date(),
      show: false,
      thisMonth: '',
      startEndDate: '',
      startDate: '',
      endDate: '',
      columns: ['全部', '支出', '收入'],
      showStatus: false,
      thisStatus: '全部',
      status: '0',
      pagination: {
        current: 1,
        size: 10,
        total: 0
      },
      finished: false,
      loading: false
    };
  },
  mounted() {
    this.getMonth();
    this.historyCode();
  },
  methods: {
    toDate,
    getMoney,
    toDetail(item) {
      this.$router.push({
        name: 'RechargeDetail',
        query: {
          item: JSON.stringify(item)
        }
      });
    },
    toHome() {
      this.$router.back();
    },
    async historyCode() {
      const params = {
        id: this.$store.getters.userId,
        status: this.status,
        startDate: this.startDate,
        endDate: this.endDate,
        ...this.pagination
      };
      const res = await getHistory({
        ...params
      });
      if (res.code === 0) {
        if (this.pagination.current === 1) {
          this.historyList = res.data.records;
        } else {
          res.data.records.map((item) => {
            this.historyList.push(item);
          });
        }
        this.pagination = {
          current: res.data.current,
          size: res.data.size,
          total: res.data.total
        };
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },

    load() {
      this.historyList.push(this.historyList);
    },

    onLoad() {
      if (
        this.pagination.current * this.pagination.size >=
        this.pagination.total
      ) {
        this.finished = true;
      } else {
        this.pagination = {
          current: this.pagination.current + 1,
          size: this.pagination.size,
          total: this.pagination.total
        };
        this.historyCode();
      }
    },

    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },

    handleChangeDate() {
      this.show = true;
    },

    newDate(time) {
      const date = new Date(time);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      month = month < 10 ? '0' + month : month;
      let day = date.getDate();
      day = day < 10 ? '0' + day : day;
      return year + '-' + month + '-' + day;
    },

    // 获取当月（第一天和最后一天）
    getThisMonth(year, month) {
      month = month - 1;
      const startDate = new Date(year, month, 1);
      const endDate = new Date(year, month + 1, 0);
      return {
        startDate: this.newDate(startDate),
        endDate: this.newDate(endDate)
      };
    },

    // 获取当前月份
    getMonth() {
      const currentDate = new Date();
      const year = new Date().getFullYear();
      this.thisMonth = ('0' + (currentDate.getMonth() + 1)).slice(-2);
      this.startDate = this.getThisMonth(year, this.thisMonth).startDate;
      this.endDate = this.getThisMonth(year, this.thisMonth).endDate;
    },

    handleSelectDate(val) {
      this.startEndDate = this.getThisMonth(
        this.newDate(val).split('-')[0],
        this.newDate(val).split('-')[1]
      );
      this.startDate = this.startEndDate.startDate;
      this.endDate = this.startEndDate.endDate;
      this.thisMonth = this.startEndDate.startDate.slice(5, 7);
      this.show = false;
      this.pagination.current = 1;
      this.historyCode();
      this.finished = false;
    },

    handleCancelDate() {
      this.pagination.current = 1;
      this.historyCode();
      this.show = false;
    },

    handleChangeStatus() {
      this.showStatus = true;
    },

    onConfirm(value, index) {
      this.thisStatus = value;
      this.showStatus = false;
      if (value === '全部') {
        this.status = '0';
      } else if (value === '收入') {
        this.status = '1';
      } else {
        this.status = '2';
      }
      this.pagination.current = 1;
      this.historyCode();
      this.finished = false;
    },

    onCancel() {
      this.pagination.current = 1;
      this.showStatus = false;
      this.finished = false;
      this.historyCode();
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  & > .nav {
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }

  & > .content {
    padding-top: 3rem;
    .nav-content {
      background: #fff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      line-height: 40px;
      height: 40px;
    }

    .history-content {
      .history-items {
        border-bottom: 1px solid #e5e5e5;
        border-top: 1px solid #e5e5e5;
        padding: 0 1.25rem;
        background-color: #fff;
        margin-bottom: 0.5rem;
        .time-content {
          border-bottom: 1px solid #e5e5e5;
          line-height: 32px;

          .item-time {
            font-size: 1rem;
            padding: 0.5rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }

      .items-content {
        line-height: 32px;
        // padding: 0.5rem 0;
        .item-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }
}

.nav-arrows-l {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 5px solid #000;
  border-bottom: 5px solid transparent;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  margin-left: 2px;
  position: absolute;
  bottom: -2px;
}
.nav-arrows-r {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 5px solid #000;
  border-bottom: 5px solid transparent;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  position: absolute;
  right: 10px;
  bottom: 5px;
}
</style>
